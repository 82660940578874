export default {
  namespaced: true,
  state: {
    smallScren: false
  },
  mutations: {
    setMobileMut (state) {
      state.smallScren = true
    },
    setPcMut (state) {
      state.smallScren = false
    }
  },
  actions: {
    screenWidthAction (context, payload) {
      if (payload >= 768) {
        context.commit('setPcMut')
      } else {
        context.commit('setMobileMut')
      }
    }
  },
  getters: {
    GetScreenStyle: (state) => {
      if (!state.smallScren) {
        return {
          width30percent: {
            width: '30%'
          },
          width60percent: {
            width: '60%'
          },
          width45percent: {
            width: '45%'
          },
          width100percent: {
            width: '100%'
          }
        }
      } else {
        return {
          width30percent: {
            width: '100%'
          },
          width60percent: {
            width: '100%'
          },
          width45percent: {
            width: '100%'
          },
          width100percent: {
            width: '100%'
          }
        }
      }
    }
  }
}
