const apiUri = {
  sendVerifyCode: '/RApi/verify_code_request', // 发送验证码
  smsCodeLogin: '/RApi/user_phone_auth', // 验证码登录
  getTokenUri: '/RApi/user_login',
  // 登入token
  getProfile: '/RApi/user_info',
  // 获取用户信息
  SignUp: '/RApi/user_register',
  getAvailableCurrency: 'feed/available_currency',
  getExchangeRate: '/feed/get_exchange_rate',
  getRealtimeRateTable: 'feed/realtime_rate_table',
  getTaxCountry: '/tax-country',
  kycIndividualSubmit: '/Kyc/individual_create',
  kycEntitySubmit: '/Kyc/entity_create',
  // kycIndividualSubmit: '/Kyc/test',
  awsGetToken: '/AWS/token',
  getAwsPic: '/AWS/presigned_url',
  uploadSign: '/Kyc/signature_set',
  fetchSign: '/Kyc/signature_get',
  fetchIndividual: '/Kyc/individual_get',
  fetchEntity: '/Kyc/entity_get',
  // Broker
  brokerSendVerifyCode: '/Referer/verify_code_request', // Broker发送验证码
  brokerSmsCodeLogin: '/Referer/user_phone_auth', // Broker验证码登录
  brokerInfo: '/Referer/get_referrer_info',
  brokerFormCreate: '/Referer/add_referrer_info',
  fetchBrokerForm: '/Referer/get_referrer_info',
  brokerAddBank: '/Referer/add_edit_bank_account',
  brokerwithdraw: '/Referer/withdrawal_application',
  brokerCustomer: '/Referer/get_referrer_customers',
  brokerIrdSubmit: 'Referer/upload_ird_file',
  brokerInvoiceSubmit: 'Referer/upload_invoice_file',
  googlePlaceAuto: '/Kyc/google_places_autocomplete'
}
// 接口文档

export {
  apiUri
}
