import Vue from 'vue'
import { Button, message, DatePicker, Tabs, FormModel, Form, Input, Switch, Radio, Checkbox, Select, Upload, Modal, Icon, Row, Col, Divider, Table, Tag, Collapse, BackTop, Steps, AutoComplete } from 'ant-design-vue'
Vue.use(Tabs)
Vue.use(FormModel)
Vue.use(Form)
Vue.use(Input)
Vue.use(Button)
Vue.use(DatePicker)
Vue.use(Switch)
Vue.use(Radio)
Vue.use(Checkbox)
Vue.use(Select)
Vue.use(Upload)
Vue.use(Modal)
Vue.use(Icon)
Vue.use(Checkbox)
Vue.use(Row)
Vue.use(Col)
Vue.use(Divider)
Vue.use(Table)
Vue.use(Tag)
Vue.use(Collapse)
Vue.use(BackTop)
Vue.use(Steps)
Vue.use(AutoComplete)

Vue.prototype.$message = message
