/* eslint-disable */
import { getLocalStorage } from '@/utils/localStorage'
import { vertifyBoolean } from '@/utils/validation'
import { getHashCode } from '@/utils/hashUser'


const entityStepSix = {
  namespaced: true,
  state: {
    entity_term_condition_check: null,
    entity_acknowledement_check: null,
    entity_crs_announcement_check: null,
    entity_self_signature_key: null,
    
  },
  mutations: {
    SetFormMutation (state, payload) {
      state[payload.content] = payload.value
    },
    SetAllState (state, payload) {
      Object.keys(state).forEach(key=>{state[key]=payload[key]})
    }
  },
  actions: {
    ChangeFormAction (context, payload) {
      context.commit('SetFormMutation', payload)
    },
    ReSetSixthFormAction (context, payload) {
      context.commit('SetAllState', payload)
    },
    initSixthFormAction (context) { 
      context.commit('SetAllState', initial)
    }
  },
  getters: {
    getSixData: (state) => {
      return state
    }
  }
}
const initial = {
  entity_term_condition_check: null,
  entity_acknowledement_check: null,
  entity_crs_announcement_check: null,
  entity_self_signature_key: null,
}
export default entityStepSix
