const entityStepTwo = {
  namespaced: true,
  state: {
    entity_pof_other_text: null,
    entity_sof_list: [],
    entity_purpose_of_transaction_other_text: null,
    entity_purpose_of_transaction_text: null,
    entity_transaction_country_list: null,
    entity_num_of_anual_transactions: null,
    entity_frequency_of_transaction: null,
    entity_total_volumn_of_trasaction_per_year: null,
    entity_average_volume_of_per_transaction: null
  },
  mutations: {
    SetFormMutation (state, payload) {
      state[payload.variable] = payload.value
    },
    SetAllState (state, payload) {
      Object.keys(state).forEach(key => {
        state[key] = payload[key]
      })
    }
  },
  actions: {
    handleChangeAction (context, payload) {
      context.commit('SetFormMutation', payload)
    },
    ReSetSecondFormAction (context, payload) {
      context.commit('SetAllState', payload)
    },
    initSecondFormAction (context) {
      context.commit('SetAllState', initial)
    }
  },
  getters: {
    getTwoData: (state) => {
      return state
    }
  }
}
const initial = {
  entity_pof_other_text: null,
  entity_sof_list: [],
  entity_purpose_of_transaction_other_text: null,
  entity_purpose_of_transaction_text: null,
  entity_transaction_country_list: null,
  entity_num_of_anual_transactions: null,
  entity_frequency_of_transaction: null,
  entity_total_volumn_of_trasaction_per_year: null,
  entity_average_volume_of_per_transaction: null
}
export default entityStepTwo
