import { brokerInfo } from '@/api/common'

export default {
  namespaced: true,
  state: {
    available_balance: 0,
    available_balance_str: '0.00',
    balance_data: {
      balance: 0,
      bank_account: '---',
      bank_account_detail: {
        account_name: '',
        account_number: '---',
        bank_name: ''
      },
      history: 0
    },
    info_data: {
      info_data: null,
      ird_status: 1,
      status: 0,
      referrer_type: 'E',
      info: null,
      ird_file_list: null,
      invoice_file_list: null
    },
    confirmed_transaction_volum: 0,
    confirmed_transaction_volum_str: '0.00',
    settlement_data: [],
    code: null,
    company: null,
    commission_current: 0,
    commission_current_str: '0.00',
    customer_count: 0,
    nation_prefix: '00',
    phone: '00000',
    email: null,
    history: 0,
    history_str: '0.00',
    transaction_volum: 0,
    transaction_volum_str: '0.00',
    status: '',
    status_str: null,
    username: null,
    id: null,
    withdrawed_str: '0.00'
  },
  mutations: {
    SetBrokerMut (state, payload) {
      Object.keys(state).forEach(
        key => { state[key] = payload[key] })
    }
  },
  actions: {
    SetBrokerAction (context) {
      return new Promise((resolve, reject) => {
        brokerInfo().then((res) => {
          context.commit('SetBrokerMut', res)
          resolve(res.info_data.info)
        }).catch(e => {
          reject(e)
        })
      })
    }
  },
  getters: {
    getIsIndividual: (state) => {
      return state.info_data.referrer_type === 'I'
    },
    getFormIsSubmit: (state) => {
      return state.info_data.info !== null
    },
    getFormIsApproved: (state) => {
      if (state.status === 'A') {
        return true
      } else {
        return false
      }
    },
    getPendingWithdraw: (state) => {
      if (state.settlement_data) {
        return state.settlement_data.filter(({ status }) => status === 'pending')
      } else {
        return []
      }
    },
    getIrdFileList: (state) => {
      const { ird_file_list: irdFileList } = state.info_data
      if (irdFileList === null) {
        return []
      } else {
        return irdFileList
      }
    },
    getInvoiceFileList: (state) => {
      const { invoice_file_list: invoiceFileList } = state.info_data
      if (invoiceFileList === null) {
        return []
      } else {
        return invoiceFileList
      }
    },
    getIrdStatus: (state) => {
      const { ird_file_list: irdFileList, ird_status: irdStatus } = state.info_data
      if (irdFileList === null & irdStatus === 0) {
        return 'none'
      } else if (irdFileList !== null & irdStatus === 0) {
        return 'wait'
      } else {
        return 'success'
      }
    },
    getInvoiceStatus: (state) => {
      const { invoice_file_list: invoiceFileList, invoice_status: invoiceStatus } = state.info_data
      if (invoiceFileList === null & invoiceStatus === 0) {
        return 'none'
      } else if (invoiceFileList !== null & invoiceStatus === 0) {
        return 'wait'
      } else {
        return 'success'
      }
    },
    getBankAccountStatus: (state) => {
      if (state.balance_data.bank_account_detail === '---' || state.balance_data.bank_account_detail == null) {
        return false
      }
      return true
    },
    getBrokerAvaliable: (state) => {
      if (state.status === 'A') {
        return true
      } else {
        return false
      }
    },
    getBankAccount: (state) => {
      // eslint-disable-next-line valid-typeof
      if (state.balance_data.bank_account_detail == null) {
        return {
          account_name: '',
          account_number: '---',
          bank_name: ''
        }
      } else {
        return state.balance_data.bank_account_detail
      }
    }
  }
}
