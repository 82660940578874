import axios from 'axios'

axios.interceptors.request.use(
  config => {
    if (config.method === 'post') {
      if (config.headers.post['Content-Type'] === 'application/x-www-form-urlencoded') {
        // config.data = QS.stringify(config.data)
      }
    }
    return config
  },
  error => {
    return Promise.error(error)
  }
)
