function vertifyBoolean (value) {
  if (value === 'false') {
    return false
  } else if (value === 'true') {
    return true
  } else {
    return null
  }
}
const chineseReg = {
  reg: /^[^\u4e00-\u9fa5]+$/
}
const emailReg = {
  reg: (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/),
  msg: 'Please put valid email.'
}
const pwdReg = {
  reg: /^[A-Za-z0-9_*-=.?]{6,15}$/,
  msg: 'Your password MUST be between 6 and 15 characters in length.'
}
export {
  emailReg,
  pwdReg,
  chineseReg,
  vertifyBoolean
}
