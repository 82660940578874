
const entityStepFive = {
  namespaced: true,
  state: {
    entity_is_head_of_government: null,
    entity_is_government_minister: null,
    entity_is_court_judge: null,
    entity_is_governor_of_bank: null,
    entity_is_foreign_representative: null,
    entity_is_armed_forces: null,
    entity_is_chief_executive: null,
    entity_is_relative_with_entity: null,
    entity_is_ownership_of_entity: null,
    entity_confirm_checkbox: null
  },
  mutations: {
    SetFormMutation (state, payload) {
      state[payload.content] = payload.value
    },
    SetObjectMutaion (state, payload) {
      state[payload.content] = payload.value
    },
    SetAllState (state, payload) {
      Object.keys(state).forEach(key => { state[key] = payload[key] })
    }
  },
  actions: {
    ChangeFormAction (context, payload) {
      context.commit('SetFormMutation', payload)
    },
    ChangeObjectAction (content, payload) {
      content.commit('SetObjectMutaion', payload)
    },
    ReSetFifthFormAction (context, payload) {
      context.commit('SetAllState', payload)
    },
    initFifthFormAction (context) {
      context.commit('SetAllState', initial)
    }
  },
  getters: {
    getFiveData: (state) => {
      return state
    }
  }
}
const initial = {
  entity_is_head_of_government: null,
  entity_is_government_minister: null,
  entity_is_court_judge: null,
  entity_is_governor_of_bank: null,
  entity_is_foreign_representative: null,
  entity_is_armed_forces: null,
  entity_is_chief_executive: null,
  entity_is_relative_with_entity: null,
  entity_is_ownership_of_entity: null,
  entity_confirm_checkbox: null
}
export default entityStepFive
