/* eslint-disable standard/no-callback-literal */
// import { getLocalStorage } from '@/utils/localStorage'
// import { vertifyBoolean } from '@/utils/validation'
// import { getHashCode } from '@/utils/hashUser'

// const hashGetLocal = function (val) {
//   return getLocalStorage(getHashCode() + val)
// }

const personStepFive = {
  namespaced: true,
  state: {
    is_head_of_government: null,
    is_government_minister: null,
    is_court_judge: null,
    is_governor_of_bank: null,
    is_foreign_representative: null,
    is_armed_forces: null,
    is_chief_executive: null,
    is_relative_with_entity: null,
    is_ownership_of_entity: null,
    confirm_checkbox: null
  },
  mutations: {
    SetFormMutation (state, payload) {
      state[payload.content] = payload.value
      // window.localStorage.setItem(getHashCode() + payload.content, payload.value)
    },
    SetObjectMutaion (state, payload) {
      state[payload.content] = payload.value
      // window.localStorage.setItem(getHashCode() + payload.content, JSON.stringify(payload.value))
    },
    SetAllState (state, payload) {
      Object.keys(state).forEach(key => { state[key] = payload[key] })
    }
  },
  actions: {
    ChangeFormAction (context, payload) {
      context.commit('SetFormMutation', payload)
    },
    ChangeObjectAction (content, payload) {
      content.commit('SetObjectMutaion', payload)
    },
    ReSetFifthFormAction (context, payload) {
      context.commit('SetAllState', payload)
    },
    initFifthFormAction (context) {
      context.commit('SetAllState', initial)
    }
  },
  getters: {
    GetStepFive: (state) => {
      return state
    }
  }
}
const initial = {
  is_head_of_government: null,
  is_government_minister: null,
  is_court_judge: null,
  is_governor_of_bank: null,
  is_foreign_representative: null,
  is_armed_forces: null,
  is_chief_executive: null,
  is_relative_with_entity: null,
  is_ownership_of_entity: null,
  confirm_checkbox: null
}
export default personStepFive
