/* eslint-disable */
import { getLocalStorage } from '@/utils/localStorage'
import { vertifyBoolean } from '@/utils/validation'
import { getHashCode } from '@/utils/hashUser'


const personStepFour = {
  namespaced: true,
  state: {
    term_condition_check: null,
    acknowledement_check: null,
    crs_announcement_check: null,
    self_signature_key: null,
  },
  mutations: {
    SetFormMutation (state, payload) {
      state[payload.content] = payload.value
      // window.localStorage.setItem(getHashCode() + payload.conten,t, payload.value)
    },
    SetAllState (state, payload) {
      Object.keys(state).forEach(key=>{state[key]=payload[key]})
    }
  },
  actions: {
    ChangeFormAction (context, payload) {
      context.commit('SetFormMutation', payload)
    },
    ReSetFourthFormAction (context, payload) {
      context.commit('SetAllState', payload)
    },
    initFourthFormAction (context) {
      context.commit('SetAllState', initial)
    }
  },
  getters: {
    GetStepFour: (state) => {
      return state
    }
  }
}
const initial = {
  term_condition_check: null,
    acknowledement_check: null,
    crs_announcement_check: null,
    self_signature_key: null,
}
export default personStepFour
