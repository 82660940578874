// import 'ant-design-vue/dist/antd.css'
import Vue from 'vue'
import router from './router'
import store from './store'
import './assets/App.css'
import i18n from './i18n'
import './plugins/antd-ui'
import './assets/css/tailwind.css'
import App from './App.vue'
import VueGtag from 'vue-gtag'
import selectionCountry from '@/assets/tax-country.json'
import countryPopularList from '@/assets/old.json'

router.afterEach((to, from, next) => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
})

// Global site tag (gtag.js) - Google Analytics
// if(process.env)
if (process.env.NODE_ENV === 'production') {
  // 线上环境加载vueGtag插件
  Vue.use(VueGtag, {
    config: { id: 'G-0LVDCEHVP5' }
  }, router)
}

Vue.directive('focus', {
  inserted: function (el, binding) {
    if (binding.value) {
      el.focus()
    }
  }
})
store.commit('countrySelection/SetCountryList', selectionCountry)
store.commit('countrySelection/SetPopularCountryList', countryPopularList)

Vue.directive('enterNumber', {
  inserted: function (el) {
    el.addEventListener('keypress', function (e) {
      e = e || window.event
      const charcode = typeof e.charCode === 'number' ? e.charCode : e.keyCode
      const re = /\d/
      if (!re.test(String.fromCharCode(charcode))) {
        if (e.preventDefault) {
          e.preventDefault()
        } else {
          e.returnValue = false
        }
      }
    })
  }
})
Vue.directive('scroll', {
  inserted: function (el, binding) {
    const f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
