<template>
  <div class=" bg-gray-main">
    <div class="sm:py-10 sm:p-0 flex flex-wrap justify-between m-auto sm:max-w-5xl w-100vw p-10vw">
      <div class="supporters-unit">
        <img
          class="supporters-img"
          src="@/assets/img/astrac_logo.png"
          alt=""
        />
        <div class="supporters-number">IND100810846-001</div>
      </div>
      <div class="supporters-unit">
        <img class="supporters-img" src="@/assets/img/acams.png" alt="" />
        <div class="supporters-name">
          ACAMS
        </div>
      </div>
      <div class="supporters-unit">
        <img class="supporters-img" src="@/assets/img/cfcs.png" alt="" />
        <div class="supporters-name">
          ASSOCIATION OF CERTIFIED FINANCIAL CRIME SPECIALISTS
        </div>
      </div>
      <div class="supporters-unit">
        <img class="supporters-img" src="@/assets/img/ica.png" alt="" />
        <div class="supporters-name">
          INTERNATIONAL COMPLIANCE ASSOCIATION
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}

</script>

<style scoped>
.supporters-img{
   border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  object-fit: contain;
}
.supporters-img:hover {
  box-shadow: 0 0 2px 1px #163257;
}
</style>
