<template>
  <div
    class="w-90vw sm:w-1000px h-auto sm:h-180px mx-auto bg-black-70 rounded-16px sm:mt-66px mt-7vh px-4vw sm:px-40px pt-5vw pb-4vw sm:pb-0 sm:pt-24px"
  >
    <div class="mt-2vw sm:mt-20px block sm:flex justify-between items-center">
      <div class="w-full flex items-center justify-between align h-15vw sm:h-64px sm:w-400px bg-white pl-2   sm:pl-20px rounded-6px">
        <div class="w-4/6 h-full sm:h-auto justify-evenly">
        <div class=" text-black-999999 text-14px sm:text-13px ">
        {{$t('common.BaseCurrency')}}
        </div>
        <input
        class=" font-600 text-blue-main text-20px h-1/2 sm:h-auto sm:text-20px w-full
        outline-none sm:w-auto tracking-wider"
        type="number" pattern="\d*"
        v-model="valueLeft">
      </div>
        <BaseCurrencySelect
        v-model="valueOne"
        @expandList ='selectionTwo=false'
        :listShow.sync ='selectionOne'
        ref="selectionOne"
        @childCurrencyChange="handleCurrencyChange"
        class=" h-15vw w-2/6 sm:w-auto floatRight"></BaseCurrencySelect>
      </div>
      <div>
      <img
      @click="handleToggleCurrency"
      class=" cursor-pointer rotate hidden sm:block sm:h-32px sm:w-32px"
      src="../assets/icon/switch.png" alt="">
      <!-- <img class="hidden sm:block" src="../assets/icon/white-right.png" alt=""> -->
      </div>
      <div class=" w-full flex items-center justify-between h-15vw sm:h-64px sm:w-400px
       mt-5vw sm:mt-0 bg-white pl-2 sm:pl-20px rounded-6px" >
        <div class="w-4/6 h-full sm:h-auto">
        <div class=" text-14px sm:text-13px text-black-999999 whitespace-no-wrap">
          {{$t('common.TransactionCurrency')}}
        </div>
        <div  class=" h-1/2 sm:h-auto font-600 flex items-center text-blue-main text-20px sm:text-20px w-full sm:w-auto tracking-wider" :value="getOutput"  type="text">{{getOutput}}
        </div>
      </div>
        <BaseCurrencySelect
        v-model="valueTwo"
        @expandList ='selectionOne=false'
        :listShow.sync ='selectionTwo'
        ref="selectionTwo"
        @childCurrencyChange="handleCurrencyChange"
        class=" h-15vw w-2/6 sm:w-auto floatRight  "></BaseCurrencySelect>
      </div>
    </div>
    <div class="flex items-center justify-items-center sm:text-center mt-4vw sm:mt-25px text-white text-16px sm:text-16px notoB">
      <p class=" flex-grow w-full text-right pr-4">1 {{valueOne.short_code}} </p>=<p class="  flex flex-shrink-0"> </p> <p class=" pl-4 justify-start flex-grow w-full text-left"> {{exchangeRate}} {{valueTwo.short_code}}</p>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
// import BaseCurrencySelection from '@/components/BaseCurrencySelection.vue'
import BaseCurrencySelect from '@/components/BaseCurrencySelect.vue'
import { getExchangeRate } from '@/api/common.js'

export default {
  created () {
    this.getRates()
  },
  props: {
    slectionOneshow: {
      type: Boolean,
      default: false
    },
    slectionTwoshow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectionOne: false,
      selectionTwo: false,
      currencyLeft: null,
      currencyRight: null,
      leftLabel: 'NZD',
      rightLabel: 'CNY',
      valueLeft: 1000,
      valueRight: 0,
      firstCurrency: '',
      secondCurrency: '',
      inputAmount: 0,
      exchangeRate: 1,
      operator: '*',
      valueOne: {
        short_code: process.env.VUE_APP_BASE_CURRENCY,
        flag: process.env.VUE_APP_BASE_CURRENCY_IMG
      },
      valueTwo: {
        short_code: 'CNY',
        flag: 'https://aus.iemoney.co.nz/assets/flag/CNY.png'
      }
    }
  },

  methods: {
    handleToggleCurrency () {
      const temp = this.valueTwo
      this.valueTwo = this.valueOne
      this.valueOne = temp
      this.getRates()
    },
    handleButtonBlur () {
      this.$nextTick(() => {
        this.$refs.selectionOne.handleButtonBlur()
        this.$refs.selectionTwo.handleButtonBlur()
      })
    },
    handleCurrencyChange () {
      this.getRates()
    },
    getRates () {
      const param = {}
      param.from = this.valueOne.short_code
      param.to = this.valueTwo.short_code
      if (param.from === param.to) {
        this.operator = '*'
        this.exchangeRate = 1
      }
      getExchangeRate(param).then(({ operator, exchangeRate }) => {
        if (operator === '*') {
          this.operator = operator
          this.exchangeRate = exchangeRate
        } else {
          this.operator = operator
          this.exchangeRate = (1 / exchangeRate).toFixed(4)
        }
      })
        .catch(e => {
          // console.log(e)
        })
    }
  },
  computed: {
    ...mapGetters([
      'availableCurrencyList'
    ]),
    ...mapState([
      'availableCurrency'
    ]),
    isSeachEmpty () {
      return this.searchValue.length === 0
    },
    getOutput () {
      return (this.valueLeft * this.exchangeRate).toFixed(2)
    }
  },
  components: {
    BaseCurrencySelect
  }
}
</script>

<style scoped>
.keyDown {
  width: 100%;
}
.width0 {
  width: 0;
}
.width5 {
  width: 5%;
}
.width95 {
  width: 95%;
}
.calculator-block {
  width: 200px;
  height: 50px;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  margin-right: 10px;
  position: relative;
}
.calculator-top {
  display: flex;
  padding: 0 50px;
  justify-content: space-between;
}
.keyDownToSearch {
  position: absolute;
  left: 20px;
  line-height: 50px;
  top: 0;
  color: #002272;
  font-weight: 600;
}
.dropdown-list li:hover {
  background-color: red;
}
.result {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  box-sizing: border-box;
  line-height: 16px;
  font-weight: 800;
  color: #002272;
  padding-left: 20px;
  align-items: center;
  cursor: pointer;
}

.result img {
  width: 50px;
  height: 36px;
  margin-right: 20px;
}
.dropdown-list li {
  display: flex;
  box-sizing: border-box;
  height: 50px;
  line-height: 16px;
  align-items: center;
  padding-left: 20px;
  font-weight: 800;
  color: #002272;
  border-bottom: 1px solid #d5d5d5;
  cursor: pointer;
}
.dropdown-list li img {
  width: 50px;
  height: 36px;
  margin-right: 20px;
}

.amountInput {
  height: 100%;
  box-sizing: border-box;
  border: none;
  padding: 0;
  padding-left: 5px;
  width: 100%;
  font-size: 20px;
  color: #163257;
  font-weight: bolder;
}

.calculator {
  width: 800px;
  height: 300px;
  margin: 0 auto;
  border-radius: 10px;
  background: #f5f5f5;
  font: 16px;
  padding-top: 15px;
}
.width120 {
  width: 220px;
  position: relative;
}
.goBtn {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: none;
  margin: 0;
}
.currency-label {
  font-size: 14px;
  font-weight: 400;
}
.dropdown-list {
  border: 1px solid black;
  width: 100%;
  height: 220px;
  overflow-y: scroll;
  box-sizing: border-box;
}
.calculator-bottom {
  width: 100%;
}
.floatRight{
  float: right;
}
.amount-input{
  color: #163257;
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  outline: none;
}
.inputH{
  height: 20%;
}
</style>
