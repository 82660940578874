const broker = {
  namespaced: true,
  state: {
    broker_surname: null,
    broker_forename: null,
    broker_maiden_name: null,
    broker_preferred_name: null,
    broker_tile: null,
    broker_gender: null,
    broker_is_nz_taxer: null,
    broker_tax_num: null,
    broker_is_gst_registered: null,
    broker_gst_number: null,
    broker_dob: null,
    broker_address: null,
    broker_home_number: null,
    broker_phone_number: null,
    broker_emaill: null,
    broker_sign_key: null,
    broker_id_type: null,
    // broker 证件
    broker_cn_identify_front_num: null,
    broker_cn_identify_front_country: null,
    broker_cn_identify_front_file_list: null,
    broker_cn_identify_back_file_list: null,

    broker_nz_au_driver_num: null,
    broker_nz_au_driver_country: null,
    broker_nz_au_driver_front_file_list: null,
    broker_nz_au_driver_back_file_list: null,

    broker_nz_au_passport_num: null,
    broker_nz_au_passport_country: null,
    broker_nz_au_passport_file_list: null,
    broker_agreement: true
  },
  mutations: {
    setStateMut (state, payload) {
      if (payload) {
        state[payload.variable] = payload.value
      } else {
        Object.keys(state).forEach((item) => { state[item] = null })
      }
    },
    SetBrokerFormMut (state, payload) {
      if (payload) {
        Object.keys(state).forEach(
          key => { state[key] = payload[key] })
      } else {
        Object.keys(state).forEach((item) => { state[item] = null })
      }
    }
  },
  actions: {
    resetSetBrokerForm (context, payload) {
      context.commit('SetBrokerFormMut', payload)
    },
    initAction (context) {
      context.commit('setStateMut')
    },
    changeFormAction (context, payload) {
      context.commit('setStateMut', payload)
    }
  },
  getters: {
    GetBrokerForm: (state) => {
      return state
    }
  }
}
export default broker
