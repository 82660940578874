const countrySelection = {
  namespaced: true,
  state: {
    popularCountryList: [],
    allCountryList: []
  },
  mutations: {
    SetCountryList (state, payload) {
      state.allCountryList = payload
    },
    SetPopularCountryList (state, payload) {
      state.popularCountryList = payload
    }
  },
  actions: {
  },
  getters: {
  }
}
export default countrySelection
