export default {
  namespaced: true,
  state: {
    isMaskShow: false
  },
  mutations: {
    toggleMask (state) {
      state.isMaskShow = !state.isMaskShow
    },
    hideMask (state) {
      if (state.isMaskShow === true) {
        state.isMaskShow = false
      }
    }
  },
  actions: {
    toggleMaskAction (context) {
      context.commit('toggleMask')
    },
    closeMaskAction (context) {
      context.commit('hideMask')
    }
  }
}
