/* eslint-disable */
import { getLocalStorage } from '@/utils/localStorage'
import { vertifyBoolean } from '@/utils/validation'
import { getHashCode } from '@/utils/hashUser'



const personStepThree = {
  namespaced: true,
  state: {
    has_authorize_other: null,
    authorize1_title: null,
    authorize1_first_name: null,
    authorize1_last_name: null,
    authorize1_gender: null,
    authorize1_date_of_birth: null,
    authorize1_place_of_birth: null,
    authorize1_nationality: null,
    authorize1_signature_key: null,
    authorize1_relation: null,
    authorize1_residential_address: null,
    authorize1_city: null,
    authorize1_country: null,
    authorize1_tax_country: null,
    authorize1_postcode: null,
    authorize1_mobile_phone: null,
    authorize1_dial_code: null,
    authorize1_suburb: null,
    authorize1_email_address: null,
    authorize1_prove_channel: null,
    authorize1_face_to_face_type: null,
    authorize1_none_face_to_face_type: null,
    authorize1_identification_type: null,
    authorize1_id_num: null,
    authorize1_issue_date: null,
    authorize1_expiry_date: null,
    authorize1_issue_country: null,
    authorize1_address_prove_file_list: null,
    
    // nz驾照
    authorize1_nz_au_driver_front_file_list: null,
    authorize1_nz_au_driver_back_file_list: null,
    authorize1_nz_au_driver_num: null,
    authorize1_nz_au_driver_country: null,
    
    // nz/au护照
    authorize1_nz_au_passport_file_list: null,
    authorize1_nz_au_passport_num: null,
    authorize1_nz_au_passport_country: null,

    // cn护照
    authorize1_cn_passport_file_list: null,
    authorize1_cn_passport_num: null,
    authorize1_cn_passport_country: 'CN',
    authorize1_cn_passport_visa_file_list: null,

    // cn身份证
    authorize1_cn_identify_front_file_list: null,
    authorize1_cn_identify_front_num: null,
    authorize1_cn_identify_front_country:'CN',
    authorize1_cn_identify_back_file_list: null,


    // 其他护照
    authorize1_other_identify_file_list: null,
    authorize1_other_identify_num: null,
    authorize1_other_identify_country: null,
    authorize1_other_passport_visa_file_list: null,

    

    // cn护照option
    authorize1_cn_passport_not_required_file_list: null,
    authorize1_cn_passport_not_required_num: null,
    authorize1_cn_passport_not_required_country:'CN',
    authorize1_cn_passport_not_required_visa_file_list: null,

    // 中国银行卡
    authorize1_cn_bank_front_file_list: null,
    authorize1_cn_bank_front_country: 'CN',
    authorize1_cn_bank_front_num: null,

    
    // authorize2 分割线-----------------------
    authorize2_title: null,
    authorize2_first_name: null,
    authorize2_last_name: null,
    authorize2_gender: null,
    authorize2_date_of_birth: null,
    authorize2_place_of_birth: null,
    authorize2_nationality: null,
    authorize2_signature_key: null,
    authorize2_tax_country: null,
    authorize2_relation: null,
    authorize2_residential_address: null,
    authorize2_city: null,
    authorize2_country: null,
    authorize2_suburb: null,
    authorize2_postcode: null,
    authorize2_dial_code: null,
    authorize2_mobile_phone: null,
    authorize2_email_address: null,
    authorize2_prove_channel: null,
    authorize2_face_to_face_type: null,
    authorize2_none_face_to_face_type: null,
    authorize2_identification_type: null,
    authorize2_id_num: null,
    authorize2_issue_date: null,
    authorize2_expiry_date: null,
    authorize2_issue_country: null,
    // nz/au护照
    authorize2_nz_au_passport_file_list: null,
    authorize2_nz_au_passport_num: null,
    authorize2_nz_au_passport_country: null,


    // cn护照
    authorize2_cn_passport_file_list: null,

    authorize2_cn_passport_num: null,
    authorize2_cn_passport_country:'CN',

    authorize2_cn_passport_visa_file_list: null,


    // cn身份证
    authorize2_cn_identify_front_file_list: null,
    authorize2_cn_identify_front_num: null,
    authorize2_cn_identify_front_country:'CN',
    authorize2_cn_identify_back_file_list: null,


    // 其他护照
    authorize2_other_identify_file_list: null,
    authorize2_other_identify_num: null,
    authorize2_other_identify_country: null,
    authorize2_other_passport_visa_file_list: null,

    // nz驾照
    authorize2_nz_au_driver_front_file_list: null,
    authorize2_nz_au_driver_num: null,
    authorize2_nz_au_driver_country: null,
    authorize2_nz_au_driver_back_file_list: null,

    // cn护照option
    authorize2_cn_passport_not_required_file_list: null,
    authorize2_cn_passport_not_required_num: null,
    authorize2_cn_passport_not_required_country:'CN',

    authorize2_cn_passport_not_required_visa_file_list: null,

    // cn银行卡
    authorize2_cn_bank_front_file_list: null,
    authorize2_cn_bank_front_country: 'CN',
    authorize2_cn_bank_front_num: null,
    authorize2_address_prove_file_list: null,

  },
  mutations: {
    SetFormMutation (state, payload) {
      state[payload.content] = payload.value
      // window.localStorage.setItem(getHashCode() + payload.content, payload.value)
    },
    SetObjectMutaion (state, payload) {
      state[payload.content] = payload.value
      // window.localStorage.setItem(getHashCode() + payload.content, JSON.stringify(payload.value))
      // window.localStorage.setItem('token', '')
    },
     SetAllState (state, payload) {
      Object.keys(state).forEach(key=>{state[key]=payload[key]})
    }
  },
  actions: {
    ChangeFormAction (context, payload) {
      context.commit('SetFormMutation', payload)
    },
    ChangeObjectAction (content, payload) {
      content.commit('SetObjectMutaion', payload)
    },
    ReSetThirdFormAction (context, payload) {
      context.commit('SetAllState', payload)
    },
    initThirdFormAction (context) {
      context.commit('SetAllState', initial)
    }
  },
  getters: {
    GetStepThree: state => (
      state
    )
  }
}
const initial ={
  has_authorize_other: null,
    authorize1_title: null,
    authorize1_first_name: null,
    authorize1_last_name: null,
    authorize1_gender: null,
    authorize1_date_of_birth: null,
    authorize1_place_of_birth: null,
    authorize1_nationality: null,
    authorize1_signature_key: null,
    authorize1_relation: null,
    authorize1_residential_address: null,
    authorize1_city: null,
    authorize1_country: null,
    authorize1_postcode: null,
    authorize1_email_address: null,
    authorize1_prove_channel: null,
    authorize1_face_to_face_type: null,
    authorize1_none_face_to_face_type: null,
    authorize1_mobile_phone: null,
    authorize1_dial_code: null,
    authorize1_suburb: null,
    authorize1_identification_type: null,
    authorize1_id_num: null,
    authorize1_issue_date: null,
    authorize1_expiry_date: null,
    authorize1_issue_country: null,
    // nz/au护照
    authorize1_nz_au_passport_file_list: null,
    authorize1_nz_au_passport_num: null,
    authorize1_nz_au_passport_country: null,


    // cn护照
    authorize1_cn_passport_file_list: null,

    authorize1_cn_passport_num: null,
    authorize1_cn_passport_country: 'CN',

    authorize1_cn_passport_visa_file_list: null,

    authorize1_tax_country: null,
    // cn身份证
    authorize1_cn_identify_front_file_list: null,

    authorize1_cn_identify_front_num: null,
    authorize1_cn_identify_front_country:'CN',

    authorize1_cn_identify_back_file_list: null,


    // 其他护照
    authorize1_other_identify_file_list: null,

    authorize1_other_identify_num: null,
    authorize1_other_identify_country: null,

    authorize1_other_passport_visa_file_list: null,


    // nz驾照
    authorize1_nz_au_driver_front_file_list: null,

    authorize1_nz_au_driver_num: null,
    authorize1_nz_au_driver_country: null,

    authorize1_nz_au_driver_back_file_list: null,

    // cn护照option
    authorize1_cn_passport_not_required_file_list: null,
    authorize1_cn_passport_not_required_num: null,
    authorize1_cn_passport_not_required_country:'CN',

    authorize1_cn_passport_not_required_visa_file_list: null,

    // cn银行卡
    authorize1_cn_bank_front_file_list: null,
    authorize1_cn_bank_front_country: 'CN',
    authorize1_cn_bank_front_num: null,
    
    authorize1_address_prove_file_list: null,

    
    // authorize2 分割线-----------------------
     authorize2_title: null,
    authorize2_first_name: null,
    authorize2_last_name: null,
    authorize2_gender: null,
    authorize2_date_of_birth: null,
    authorize2_place_of_birth: null,
    authorize2_nationality: null,
    authorize2_signature_key: null,
    authorize2_tax_country: null,
    authorize2_relation: null,
    authorize2_residential_address: null,
    authorize2_city: null,
    authorize2_country: null,
    authorize2_postcode: null,
    authorize2_home_telephone: null,
    authorize2_email_address: null,
    authorize2_prove_channel: null,
    authorize2_face_to_face_type: null,
    authorize2_none_face_to_face_type: null,
    authorize2_mobile_phone: null,
    authorize2_suburb: null,
    authorize2_identification_type: null,
    authorize2_id_num: null,
    authorize2_dial_code: null,
    authorize2_issue_date: null,
    authorize2_expiry_date: null,
    authorize2_issue_country: null,

    // nz/au护照
    authorize2_nz_au_passport_file_list: null,
    authorize2_nz_au_passport_num: null,
    authorize2_nz_au_passport_country: null,


    // cn护照
    authorize2_cn_passport_file_list: null,

    authorize2_cn_passport_num: null,
    authorize2_cn_passport_country:'CN',

    authorize2_cn_passport_visa_file_list: null,


    // cn身份证
    authorize2_cn_identify_front_file_list: null,

    authorize2_cn_identify_front_num: null,
    authorize2_cn_identify_front_country:'CN',

    authorize2_cn_identify_back_file_list: null,


    // 其他护照
    authorize2_other_identify_file_list: null,

    authorize2_other_identify_num: null,
    authorize2_other_identify_country: null,

    authorize2_other_passport_visa_file_list: null,


    // nz驾照
    authorize2_nz_au_driver_front_file_list: null,

    authorize2_nz_au_driver_num: null,
    authorize2_nz_au_driver_country: null,

    authorize2_nz_au_driver_back_file_list: null,

    // cn护照option
    authorize2_cn_passport_not_required_file_list: null,
    authorize2_cn_passport_not_required_num: null,
    authorize2_cn_passport_not_required_country:'CN',

    authorize2_cn_passport_not_required_visa_file_list: null,

    // cn银行卡
    authorize2_cn_bank_front_file_list: null,

    

    authorize2_cn_bank_front_country: 'CN',
    authorize2_cn_bank_front_num: null,
    
    authorize2_address_prove_file_list: null
}
export default personStepThree
