<template>
  <div class="container">
    <div class="banner">
      <span>Menu</span>
      <img
        v-on:click="HandleMenuClick"
        class="menuIcon"
        src="@/assets/icon/menu_blue.png"
        alt=""
      />
    </div>
    <div class="bottomBorder">
      <router-link v-on:click.native="HideMenu" to="/" class="jumpItem"
        >{{$t('common.IEMoney')}}</router-link
      >
      <router-link
        v-on:click.native="HideMenu"
        to="/products-solutions"
        class="jumpItem"
        >{{$t('common.Products-Solutions')}}</router-link
      >
      <router-link
        v-on:click.native="HideMenu"
        to="/affiliates"
        class="jumpItem"
        >{{$t('common.AffiliatesProgramme')}}</router-link
      >
      <router-link v-on:click.native="HideMenu" to="/about" class="jumpItem"
        >{{$t('common.About')}}</router-link
      >
      <router-link v-on:click.native="HideMenu" to="/contact" class="jumpItem"
        >{{$t('common.Contact')}}</router-link
      >
      <router-link v-on:click.native="HideMenu" to="/faq" class="jumpItem"
        >{{$t('common.FAQ')}}</router-link
      >
      <router-link
        v-on:click.native="HideMenu"
        :to="{name: 'UserPage'}"
        class="jumpItem"
        >{{$t('common.MyAccount')}}</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    HandleMenuClick () {
      this.$emit('MenuClick')
    },
    HideMenu () {
      this.$emit('MenuClick')
    }
  }
}

</script>

<style lang="less" scoped>
.container{
  width: 300px;
  min-height: 100vh;
  background-color: #FFFFFF;
  z-index: 20;
}
.bottomBorder{
  border-bottom: 1px solid  #E9E9E9;

}
.menuIcon{
  width: 32px;
  height: 24px;
  margin-left: 20px;
  cursor: pointer;
  margin-left: 30px;
}
.jumpItem{
  cursor: pointer;
  box-sizing: border-box;
  padding-left: 24px;
  border-top: 1px solid  #E9E9E9;
  width:100%;
  height: 48px;
  display: block;
  text-decoration: none;
  line-height: 48px;
  background: #FFFFFF;
  font-weight: 400;
  color: #163257;
  &.router-link-exact-active {
     background-color: #163257;
      color: #FFFFFF;
    }
}
.banner{
  height: 100px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  color: #163257;
  padding-left: 160px;
  padding-bottom: 10px;
}
.jumpItem:hover{
  background-color: #163257;
  color: #FFFFFF;
}
@media only screen and (max-width: 768px) {
.container{
  width: 60vw;
  min-height: 100vh;
  background-color: #FFFFFF;
}
.bottomBorder{
  border-bottom: 1px solid  #E9E9E9;

}
.menuIcon{
  width: 32px;
  height: 24px;
  margin-left: 5vw;
  cursor: pointer;
}
.jumpItem{
  cursor: pointer;
  box-sizing: border-box;
  border-top: 1px solid  #E9E9E9;
  width:100%;
  height: 7vh;
  display: block;
  text-decoration: none;
  line-height: 7vh;
  background: #FFFFFF;
  font-weight: 400;
  color: #163257;
  padding-left: 10vw;
  &.router-link-exact-active {
     background-color: #163257;
      color: #FFFFFF;
    }
}
.banner{
  height: 15vw;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  color: #163257;
  padding-left: 10vw;
  padding-bottom: 0vw;
}
.jumpItem:hover{
  background-color: #163257;
  color: #FFFFFF;
}
}
</style>
