const entityStepFour = {
  namespaced: true,
  state: {
    entity_has_authorize_other: null,
    entity_authorize_relationship: null,
    entity_authorize_title: null,
    entity_authorize_first_name: null,
    entity_authorize_last_name: null,
    entity_authorize_gender: null,
    entity_authorize_dob: null,
    entity_authorize_nationality: null,
    entity_authorize_place_of_birth: null,
    entity_authorize_tax_country: null,
    entity_authorize_tax_num: null,
    entity_authorize_address: null,
    entity_authorize_country: null,
    entity_authorize_city: null,
    entity_authorize_suburb: null,
    entity_authorize_postcode: null,
    entity_authorize_dialcode: null,
    entity_authorize_phonenum: null,
    entity_authorize_email: null,
    entity_authorize_identification_type: null,
    entity_authorize_id_num: null,
    entity_authorize_issue_date: null,
    entity_authorize_expire_date: null,
    entity_authorize_issue_country: null,

    entity_authorize_addressProveType: null,
    entity_authorize_address_file_list: null,

    entity_authorize_proveChannel: null,
    entity_authorize_proveMethod: null,

    entity_authorize_nz_au_passport_num: null,
    entity_authorize_nz_au_passport_country: null,
    entity_authorize_nz_au_passport_file: null,
    entity_authorize_nz_au_driver_num: null,
    entity_authorize_nz_au_driver_country: null,
    entity_authorize_nz_au_driver_front_file: null,
    entity_authorize_nz_au_driver_back_file: null,
    entity_authorize_cn_passport_num: null,
    entity_authorize_cn_passport_country: 'CN',
    entity_authorize_cn_passport_file: null,
    entity_authorize_cn_passport_visa_file: null,
    entity_authorize_cn_identify_num: null,
    entity_authorize_cn_identify_country: 'CN',
    entity_authorize_cn_identify_front_file: null,
    entity_authorize_cn_identify_back_file: null,
    entity_authorize_cn_bank_num: null,
    entity_authorize_cn_bank_country: 'CN',
    entity_authorize_cn_bank_file: null,
    entity_authorize_cn_passport_option_num: null,
    entity_authorize_cn_passport_option_country: 'CN',
    entity_authorize_cn_passport_option_file: null,
    entity_authorize_cn_passport_visa_option_file: null,
    entity_authorize_other_passport_option_num: null,
    entity_authorize_other_passport_option_country: null,
    entity_authorize_other_passport_option_file: null,
    entity_authorize_other_passport_visa_option_file: null,

    entity_authorize_sign_key: null
  },
  mutations: {
    SetFormMutation (state, payload) {
      state[payload.variable] = payload.value
    },
    SetAllState (state, payload) {
      Object.keys(state).forEach(key => {
        state[key] = payload[key]
      })
    }
  },
  actions: {
    handleChangeAction (context, payload) {
      context.commit('SetFormMutation', payload)
    },
    ReSetFourthFormAction (context, payload) {
      context.commit('SetAllState', payload)
    },
    initFourthFormAction (context) {
      context.commit('SetAllState', initial)
    }
  },
  getters: {
    getFourData: (state) => {
      return state
    }
  }
}
const initial = {
  entity_has_authorize_other: null,
  entity_authorize_relationship: null,
  entity_authorize_title: null,
  entity_authorize_first_name: null,
  entity_authorize_last_name: null,
  entity_authorize_gender: null,
  entity_authorize_dob: null,
  entity_authorize_nationality: null,
  entity_authorize_place_of_birth: null,
  entity_authorize_tax_country: null,
  entity_authorize_tax_num: null,
  entity_authorize_address: null,
  entity_authorize_country: null,
  entity_authorize_city: null,
  entity_authorize_suburb: null,
  entity_authorize_postcode: null,
  entity_authorize_dialcode: null,
  entity_authorize_phonenum: null,
  entity_authorize_email: null,
  entity_authorize_identification_type: null,
  entity_authorize_id_num: null,
  entity_authorize_issue_date: null,
  entity_authorize_expire_date: null,
  entity_authorize_issue_country: null,

  entity_authorize_addressProveType: null,
  entity_authorize_address_file_list: null,

  entity_authorize_proveChannel: null,
  entity_authorize_proveMethod: null,

  entity_authorize_nz_au_passport_num: null,
  entity_authorize_nz_au_passport_country: null,
  entity_authorize_nz_au_passport_file: null,
  entity_authorize_nz_au_driver_num: null,
  entity_authorize_nz_au_driver_country: null,
  entity_authorize_nz_au_driver_front_file: null,
  entity_authorize_nz_au_driver_back_file: null,
  entity_authorize_cn_passport_num: null,
  entity_authorize_cn_passport_country: 'CN',
  entity_authorize_cn_passport_file: null,
  entity_authorize_cn_passport_visa_file: null,
  entity_authorize_cn_identify_num: null,
  entity_authorize_cn_identify_country: 'CN',
  entity_authorize_cn_identify_front_file: null,
  entity_authorize_cn_identify_back_file: null,
  entity_authorize_cn_bank_num: null,
  entity_authorize_cn_bank_country: 'CN',
  entity_authorize_cn_bank_file: null,
  entity_authorize_cn_passport_option_num: null,
  entity_authorize_cn_passport_option_country: 'CN',
  entity_authorize_cn_passport_option_file: null,
  entity_authorize_cn_passport_visa_option_file: null,
  entity_authorize_other_passport_option_num: null,
  entity_authorize_other_passport_option_country: null,
  entity_authorize_other_passport_option_file: null,
  entity_authorize_other_passport_visa_option_file: null,

  entity_authorize_sign_key: null
}
export default entityStepFour
