<template>
  <div>
    <div class="homePage pt-0 sm:pt-0">
      <div class=" hidden sm:block w-90vw sm:w-1000px mx-auto text-white font-400  sm:pt-166px pt-10vh main-title sm:text-40px text-2rem text-center px-2vw leading-2.5rem sm:leading-72px sm:px-35px sm:main-title-sm test notoB box"
      v-scroll = "handleScroll"
      >The fast and affordable way to send money from
      <span class=" hover:h-32 fontReg inline-block font-900 text-orange-main">{{baseCurrency}}</span>
        to
      <span class="fontReg inline-block font-900 text-orange-main">CNY</span>
       </div>
       <div class=" text-27px block sm:hidden   leading-32px pt-14vh text-white text-center px-10vw notoB tracking-wider">
         Safe and secure payment service
       </div>
      <BaseEX
      ref="baseEx"
      ></BaseEX>
      <div class="w-50vw h-10vw sm:w-264px sm:h-54px bg-orange-main text-1125rem sm:text-18px font-400 text-white text-center mt-10vw sm:mt-38px leading-10vw sm:leading-54px mx-auto accountBtn cursor-pointer rounded-100px openAccount  tracking-wider notoB"
      @click="handleJumptoSign"
      >Get Started</div>
    </div>
    <div class=" text-center h-15vw sm:h-160px  text-750rem sm:text-36px font-400 sm:leading-160px leading-15vw">
      ZERO FEES - Saving you time, money and worries
    </div>
    <BaseProducts></BaseProducts>
    <BaseBusiness></BaseBusiness>
    <div class=" bg-gray-main">
      <div class=" m-auto w-full sm:w-1440px sm:pt-88px sm:px-330px sm:pb-103px p-6vw">
        <div class=" w-100px h-6px bg-orange-main rounded-6px m-auto margin: 0 auto"></div>
        <div class=" font-500 text-2xl sm:text-48px uppercase text-center mt-2vw sm:mt-20px">Currencies</div>
        <div class=" mt-3vw sm:mt-72px flex flex-wrap pl-5vw sm:pl-0" >
          <BaseFlag v-for='item in flagsArray' v-bind:key="item.id" :flag="item.label"></BaseFlag>
        </div>
      </div>
    </div>
    <BaseAbout></BaseAbout>
    <BaseSupporters></BaseSupporters>

  </div>
</template>

<script>
import BaseSupporters from '@/components/BaseSupporters.vue'
import BaseEX from '@/components/BaseEX.vue'
import BaseFlag from '@/components/BaseFlag.vue'
import BaseProducts from '@/components/BaseProducts.vue'
import BaseBusiness from '@/components/BaseBusiness.vue'
import BaseAbout from '@/components/BaseAbout.vue'

export default {
  data () {
    return {
      baseCurrency: process.env.VUE_APP_BASE_CURRENCY,
      leftTimer: '',
      rightTimer: '',
      value: 0,
      testValue: '',
      selectionOneShow: false,
      selectionTwoShow: false,
      leftIndex: 0,
      rightIndex: 3,
      showList: [],
      testArray: [1, 2, 3, 4, 5, 6],
      flagsArray: [
        { id: 0, label: 'USD' },
        { id: 1, label: 'EUR' },
        { id: 2, label: 'GBP' },
        { id: 3, label: 'JPY' },
        { id: 4, label: 'CHF' },
        { id: 5, label: 'CAD' },
        { id: 6, label: 'SGD' },
        { id: 7, label: 'AUD' },
        { id: 8, label: 'NZD' },
        { id: 9, label: 'HKD' },
        { id: 10, label: 'CHN' },
        { id: 11, label: 'ZAF' },
        { id: 12, label: 'DKK' },
        { id: 13, label: 'NOK' },
        { id: 14, label: 'SEK' }
      ]
    }
  },
  components: {
    BaseSupporters,
    BaseEX,
    BaseFlag,
    BaseProducts,
    BaseBusiness,
    BaseAbout
  },

  mounted () {
    document.getElementById('app').onclick = () => {
      if (this.$refs.baseEx) {
        this.closeMenu()
      }
    }
  },
  methods: {
    handleScroll: function (evt, el) {
      if (window.scrollY > 0) {
        el.setAttribute('style', 'opacity: 1; transform: translate3d(0, -10px, 0)')
      }
      return window.scrollY > 100
    },
    handleUpdate (e) {
      this.testValue = e
    },
    closeMenu () {
      this.$refs.baseEx.handleButtonBlur()
    },
    handleJumptoSign () {
      this.$router.push({
        name: 'SmsLogin'
      })
    }
  },
  beforeDestroy () {
    document.getElementById('app').onclick = null
    clearTimeout(this.leftTimer)
    clearTimeout(this.rightTimer)
  }
}

</script>

<style scoped>
.box{
  opacity: 0.7;
  color: white;
  backface-visibility: hidden;
  transition: 1.5s all cubic-bezier(0.39, 0.575, 0.565, 1);
}
.phoneIcon{
  width: 16px;
  height: 16px;
}
.openAccount{
  transition: all .15s linear;
}
.openAccount:active{
  transform: scale(1.05);
}
.openAccount:hover{
  transform: scale(1.05);
}
.download-btn{
  transition: all .15s linear;
}
.download-btn:active{
  transform: scale(1.05);
}
.download-btn:hover{
  transform: scale(1.05);
}
.maintitle {
  text-shadow: 0px 6px 40px rgba(0, 0, 0, 0.3);
}

.accountBtn {
  box-shadow: 0px 2px 40px 0px rgba(0, 0, 0, 0.3);
}
.mainSubtitle {
  text-shadow: 0px 6px 40px rgba(0, 0, 0, 0.3);
}
.homePage {
  background: url("~@/assets/bg/bg_home.png") no-repeat center/cover;
  height: 100vh;
  width: 100vw;
}
.slide-fade-enter-active ,.slide-fade-leave-active{
  transition: all 0.3s ease;
  transform: translateY(0);
}
.slide-fade-enter , .slide-fade-leave-to
{
  transition: all 0.3s ease;
  transform: translateY(5%);
}

</style>
