import axios from 'axios'
import router from '../router'
import { message } from 'ant-design-vue'
import Vue from 'vue'
import { removeLocalStorage } from '@/utils/localStorage'

Vue.use(message)
axios.interceptors.response.use(
  // axios 响应拦截
  response => {
    if (response.status === 200) {
      if (response.data.error_code === 0 && response.data.message === 'OK') {
        return Promise.resolve(response.data.data)
      } else if (response.data.error_code === 103) {
        removeLocalStorage('token')
        message.error('Auth Fail')
        router.push({ name: 'SmsLogin' })
        return Promise.reject(response.data)
      } else {
        return Promise.reject(response.data)
      }
    } else {
      // removeLocalStorage('token')
      // Router.push('/login')
      return Promise.reject(response.data)
    }
  },
  error => {
    // removeLocalStorage('token')
    // Router.push('/login')
    return Promise.reject(error.data)
  }
)
