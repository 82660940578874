import { getLocalStorage } from '@/utils/localStorage'
import { getCustomerProfile } from '@/api/common'
const login = {
  namespaced: true,
  state: {
    attributes: {
      account_holder: null,
      account_status: 'I',
      account_status_str: 'inactive',
      compliance_status: null,
      compliance_status_str: '',
      id: '000000',
      info_id: null,
      mobilephone: null,
      risk_level: null,
      risk_level_str: '',
      tax_country: null
    },
    user: {
      email: 'xxxxx@xxxx.com',
      language: 'English',
      type: null,
      type_str: '',
      username: 'NZIE00000'
    },
    status: {
      account_active: true,
      basic_info_completed: false,
      compliance_acitve: false,
      email_verified: false,
      login_type: null,
      login_type_str: null
    },
    // user: JSON.parse(getLocalStorage('user')),
    token: getLocalStorage('token'),
    hasLogIn: false,
    AwsToken: JSON.parse(getLocalStorage('AwsToken'))
  },
  mutations: {
    SetToken (state, payload) {
      state.token = payload
      window.localStorage.setItem('token', payload)
    },
    clearToken (state) {
      state.token = ''
      window.localStorage.setItem('token', '')
    },
    SetHasLogIn (state) {
      state.hasLogIn = true
    },
    SetUserDetailProfile (state, payload) {
      state.attributes = payload.attributes
      state.status = payload.status
    },
    SetAwsToken (state, payload) {
      state.AwsToken = payload
      window.localStorage.setItem('AwsToken', JSON.stringify(payload))
    },
    SetStatusMut (state, payload) {
      state.status = payload
    }
  },
  actions: {
    SetStatusAction (context, payload) {
      context.commit('SetStatusMut', payload)
    },
    SetTokenAction (context, payload) {
      return new Promise((resolve) => {
        context.commit('SetToken', payload)
        resolve()
      })
    },
    SetAwsAction (context, payload) {
      context.commit('SetAwsToken', payload)
    },
    SetHasLogInAction (context) {
      context.commit('SetHasLogIn')
    },
    setUserDetailProfileAction (context, payload) {
      return new Promise((resolve, reject) => {
        getCustomerProfile().then((res) => {
          context.commit('SetUserDetailProfile', res)
          resolve()
        }).catch(e => {
          return reject(e)
        })
      })
    }
  },
  getters: {
    getComplainceActive: (state) => {
      if (state.status.compliance_acitve) {
        return state.status.compliance_acitve
      } else {
        return false
      }
    }
  }
}
export default login
