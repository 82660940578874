<template>
<div
  @click.stop
  class="selection-calculator-block">
  <div @click="toggleListShow"
  class="selection-Container rounded-6px">
    <img class="img-Flag" :src="currentCurrency.flag" alt="">
    <div class="shortCode">{{currentCurrency.short_code}}</div>
    <img :class="[listShow? 'rotate':'','downIcon']" src="@/assets/icon/gray-down.png" alt="">
  </div>
  <div :class="[listShow?'height180px':'','dropdown-list']" >
    <ul class=" bg-gray-main">
      <li
      class="selection-Container height42px zindex2  borderBottom"
      v-for="item in availableCurrency"
      :key="item.short_code"
      @click="handleSectionCurrency(item)"
      >
      <img class="img-Flag" :src="item.flag" alt="">
      <div class="shortCode">{{item.short_code}}</div>
      </li>
    </ul>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'

export default {

  data () {
    return {
    }
  },
  model: {
    prop: 'currentCurrency',
    event: 'changeCurrency'
  },
  props: {
    propName: {
      type: Number,
      default: 0
    },
    listShow: {
      type: Boolean,
      default: false
    },
    currentCurrency: {
      type: Object
    }
  },
  computed: {
    ...mapState([
      'availableCurrency'
    ]),
    childCurrency: {
      get () {
        return this.currentCurrency
      },
      set (val) {
        this.$emit('changeCurrency', val)
        this.$emit('update:listShow', false)
      }
    }
  },
  methods: {
    toggleListShow () {
      this.$emit('update:listShow', !this.listShow)
      if (!this.listShow) {
        this.$emit('expandList')
      }
    },
    handleButtonBlur () {
      this.$emit('update:listShow', false)
    },
    handleSectionCurrency (e) {
      this.childCurrency = e
      this.$emit('childCurrencyChange')
    }
  }
}
</script>

<style scoped>
.selection-calculator-block{
  position: relative;
  height: 100%;
  width: 150px;
  background: #f5f5f5;
  border-radius: 6px;
}
.borderBottom{
  border-bottom: 1px solid #f5f5f5;
}
.selection-Container{
  height: inherit;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  position: relative;
}
.img-Flag{
  width: 36px;
  height: 24px;
}
.shortCode{
  color: #333333;
  font-weight: 800;
  margin: 0 10px;
}
.downIcon{
  width: 20px;
  height: 16px;
  transition: transform .2s ease-in-out;
  transform: rotate(90deg);
}
.rotate{
  transform: rotate(0deg);
}
.dropdown-list{
  position: absolute;
  width: 100%;
  height: 0;
  background: #FFF;
  overflow-y: scroll;
  transition: 0.2s ease-in-out;
}
.height180px{
  height: 180px;
}
.height42px{
  height: 36px;
  background: #FFF;
}
.height42px:hover{
  background: #f5f5f5;
}
.zindex2{
  z-index: 2;
}
@media only screen and (max-width: 600px) {
.selection-calculator-block{
  display: flex;
}
.selection-Container{
  justify-content: space-evenly;
}
.height180px{
  height: 30vh;
}
.height42px{
  height: 6vh;
  background: #FFF;
}

}
</style>
