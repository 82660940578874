const entityStepOne = {
  namespaced: true,
  state: {
    entity_name: null,
    entity_phone: null,
    entity_register_num: null,
    entity_tax_num: null,
    entity_industry_field: null,
    entity_job_desc: null,

    entity_address: null,
    entity_country: null,
    entity_city: null,
    entity_suburb: null,
    entity_postcode: null,
    entity_email: null,
    entity_wechat: null,
    entity_addressProveType: null,
    entity_address_file_list: null,
    // 公司税务
    entity_is_nz_taxer: null,
    entity_nz_tax_number: null,
    entity_tax_panes: [
      { title: 'Tax 税务#1', key: 1, closeable: false, tax_country: '', tax_number: '' }
    ],
    entity_us_taxer_file_list: [],
    // 公司状态
    entity_status_radio: null,
    Active_Non_Financial_Entity_check: null,
    Passive_Non_Financial_Entity_check: null,
    Financial_Institution_check: null,

    controlling_person_panes: [
      { title: 'Person 控制人1', fullname: '', key: 1, closeable: false, position: '', tax_country: '', tax_number: '' }
    ]
  },
  mutations: {
    SetFormMutation (state, payload) {
      state[payload.content] = payload.value
    },
    SetObjectMutaion (state, payload) {
      state[payload.content] = payload.value
    },
    SetInnerMut (state, payload) {
      const { obj, index, child, value } = payload
      state[obj][index][child] = value
    },
    SetAllState (state, payload) {
      Object.keys(state).forEach(
        key => { state[key] = payload[key] })
    }
  },
  actions: {
    ChangeInnerAction (context, payload) {
      context.commit('SetInnerMut', payload)
    },
    ChangeFormAction (context, payload) {
      context.commit('SetFormMutation', payload)
    },
    ChangeObjectAction (content, payload) {
      content.commit('SetObjectMutaion', payload)
    },
    ReSetFirstFormAction (context, payload) {
      context.commit('SetAllState', payload)
    },
    initFirstFormAction (context, data) {
      context.commit('SetAllState', { ...initial, ...data })
    }
  },
  getters: {
    getTaxPanesLength: (state) => (
      state.entity_tax_panes.length
    ),
    getTaxPanesLastKey: (state) => {
      return +state.entity_tax_panes[state.entity_tax_panes.length - 1].key || 0
    },
    getTaxIncludeUS: (state) => {
      return state.entity_tax_panes.findIndex((item) => {
        return (item.tax_country === 'US')
      }) > -1
    },
    getOneData: (state) => {
      return state
    }
  }
}
const initial = {
  entity_name: null,
  entity_phone: null,
  entity_register_num: null,
  entity_tax_num: null,
  entity_industry_field: null,
  entity_job_desc: null,

  entity_address: null,
  entity_country: null,
  entity_city: null,
  entity_suburb: null,
  entity_postcode: null,
  entity_email: null,
  entity_wechat: null,
  entity_addressProveType: null,
  entity_address_file_list: null,
  // 公司税务
  entity_is_nz_taxer: null,
  entity_nz_tax_number: null,
  entity_tax_panes: [
    { title: 'Tax 税务#1', key: 1, closeable: false, tax_country: '', tax_number: '' }
  ],
  entity_us_taxer_file_list: [],
  // 公司状态
  entity_status_radio: null,
  Active_Non_Financial_Entity_check: null,
  Passive_Non_Financial_Entity_check: null,
  Financial_Institution_check: null,

  controlling_person_panes: [
    { title: 'Person 控制人1', fullname: '', key: 1, closeable: false, position: '', tax_country: '', tax_number: '' }
  ]
}
export default entityStepOne
