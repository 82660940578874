/* eslint-disable no-unused-vars */
import { post, get } from './base'
import { apiUri } from '../api/api_default'
// import QS from 'qs'

export const getToken = params => post(apiUri.getTokenUri, params)
export const SignUp = params => post(apiUri.SignUp, params)
export const getCustomerProfile = (params = {}) => get(apiUri.getProfile, params, true)
export const getAvailableCurrency = (params = {}) => get(apiUri.getAvailableCurrency, params)

export const getExchangeRate = params => get(apiUri.getExchangeRate, params)

export const kycIndividualSubmit = params => post(apiUri.kycIndividualSubmit, params, true)

export const kycEntitySubmit = params => post(apiUri.kycEntitySubmit, params, true)

export const awsGetToken = params => get(apiUri.awsGetToken, params, true)

export const getAwsPic = params => get(apiUri.getAwsPic, params, true)

export const uploadSign = params => post(apiUri.uploadSign, params, false, false)

export const fetchSign = params => get(apiUri.fetchSign, params, true)

export const fetchIndividual = params => get(apiUri.fetchIndividual, params, true)

export const fetchEntity = params => get(apiUri.fetchEntity, params, true)

export const sendVerifyCode = params => post(apiUri.sendVerifyCode, params)

export const smsCodeLogin = params => post(apiUri.smsCodeLogin, params)

export const brokerSendVerifyCode = params => post(apiUri.brokerSendVerifyCode, params)

export const brokerSmsCodeLogin = params => post(apiUri.brokerSmsCodeLogin, params)

export const brokerInfo = params => get(apiUri.brokerInfo, params, true)

export const brokerFormCreate = params => post(apiUri.brokerFormCreate, params, true)

export const fetchBrokerForm = params => post(apiUri.fetchBrokerForm, params, true)

export const brokerAddBank = params => post(apiUri.brokerAddBank, params, true)

export const brokerWithdraw = params => post(apiUri.brokerwithdraw, params, true)

export const brokerCustomer = params => get(apiUri.brokerCustomer, params, true)

export const brokerIrdSubmit = params => post(apiUri.brokerIrdSubmit, params, true)

export const brokerInvoiceSubmit = params => post(apiUri.brokerInvoiceSubmit, params, true)

export const googlePlaceAuto = params => get(apiUri.googlePlaceAuto, params, true)
