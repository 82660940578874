<template>
  <div class="bg">
    <transition name="slide-fade">
      <TopBar
        v-show="!isMaskShow"
        class="fixed top-0 z-10"
        @MenuClick="maskToggle"
        :bgBlue="isBlue"
        :blueTrigger="getCurrentURL"
      ></TopBar>
    </transition>

    <router-view></router-view>
    <transition name="slide-fade">
      <BaseMask v-show="isMaskShow" @MaskToggle="maskToggle">
        <transition>
          <RightNavBar
            name="swiper"
            class="right-Nav-Bar z-20 navbar"
            @MenuClick="closeMaskAction"
          ></RightNavBar>
        </transition>
      </BaseMask>
    </transition>
    <BaseBotBanner :isShow="getBottomShow"></BaseBotBanner>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import BaseMask from '@/components/BaseMask.vue'
import TopBar from '@/components/TopBar.vue'
import RightNavBar from '@/components/RightNavBar.vue'
import BaseBotBanner from '@/components/BaseBotBanner.vue'

export default {

  mounted () {
    window.addEventListener('scroll', this.isTop)
  },
  components: {
    TopBar,
    RightNavBar,
    BaseMask,
    BaseBotBanner
  },
  data () {
    return {
      isBlue: false
    }
  },
  methods: {
    isTop () {
      var scrollTop = window.pageYOffset || document.documentElement.scrollTop
      if (scrollTop >= 87) {
        this.isBlue = true
      } else {
        this.isBlue = false
      }
    },
    ...mapActions('mask', ['toggleMaskAction', 'closeMaskAction']),
    maskToggle () {
      this.toggleMaskAction()
    }
  },
  computed: {
    getBottomShow () {
      const { path } = this.$route
      const blackList = ['/forgot_password', '/signup', '/login', '/affiliates/affiliates_sign', '/sms-login']
      if (blackList.includes(path)) {
        return false
      } else {
        return true
      }
    },
    getCurrentURL () {
      const { path } = this.$route
      const whiteList = [
        '/signup',
        '/signup/',
        '/login',
        '/login/',
        '/affiliates/affiliates_sign',
        '/forgot_password',
        '/sms-login',
        '/sms-login/',
        '/privacy-policy',
        '/terms-of-service'
      ]
      if (whiteList.includes(path)) {
        return false
      } else {
        return true
      }
    },
    ...mapState('mask', ['isMaskShow'])
    // ...mapMutations(['hideMask'])
  }
}
</script>

<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
  opacity: 1;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}
.slide-fade-enter-active .navbar,
.slide-fade-leave-active .navbar {
  transition: all 0.3s ease;
  transform: translateX(0);
}
.slide-fade-enter .navbar,
.slide-fade-leave-to .navbar {
  transition: all 0.3s ease;
  transform: translateX(100%);
}

.right-Nav-Bar {
  top: 0;
  position: fixed;
  right: 0;
}
@media only screen and (max-width: 768px) {
  .bg {
    min-height: 100vh;
  }
}
</style>
