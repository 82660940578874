const brokerEntity = {
  namespaced: true,
  state: {
    entity_broker_company_name: null,
    entity_broker_company_address: null,
    entity_broker_surname: null,
    entity_broker_forename: null,
    entity_broker_maiden_name: null,
    entity_broker_preferred_name: null,
    entity_broker_tile: null,
    entity_broker_gender: null,
    entity_broker_is_gst_registered: null,
    entity_broker_gst_number: null,
    entity_broker_dob: null,
    entity_broker_address: null,
    entity_broker_home_number: null,
    entity_broker_phone_number: null,
    entity_broker_emaill: null,
    entity_broker_sign_key: null,
    entity_broker_id_type: null,
    // broker 证件
    entity_broker_cn_identify_front_num: null,
    entity_broker_cn_identify_front_country: null,
    entity_broker_cn_identify_front_file_list: null,
    entity_broker_cn_identify_back_file_list: null,

    entity_broker_nz_au_driver_num: null,
    entity_broker_nz_au_driver_country: null,
    entity_broker_nz_au_driver_front_file_list: null,
    entity_broker_nz_au_driver_back_file_list: null,

    entity_broker_nz_au_passport_num: null,
    entity_broker_nz_au_passport_country: null,
    entity_broker_nz_au_passport_file_list: null,
    entity_broker_agreement: true
  },
  mutations: {
    setStateMut (state, payload) {
      if (payload) {
        state[payload.variable] = payload.value
      } else {
        Object.keys(state).forEach((item) => { state[item] = null })
      }
    },
    SetBrokerFormMut (state, payload) {
      if (payload) {
        Object.keys(state).forEach(
          key => { state[key] = payload[key] })
      } else {
        Object.keys(state).forEach((item) => { state[item] = null })
      }
    }
  },
  actions: {
    resetSetBrokerEntityForm (context, payload) {
      context.commit('SetBrokerFormMut', payload)
    },
    initAction (context) {
      context.commit('setStateMut')
    },
    changeFormAction (context, payload) {
      context.commit('setStateMut', payload)
    }
  },
  getters: {
    GetBrokerForm: (state) => {
      return state
    }
  }
}

export default brokerEntity
